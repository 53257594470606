import React from 'react';
import {render} from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import {createRoot} from 'react-dom/client';
//import Reboot from '@material-ui/core/Reboot';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
//import {purple,green,grey,pink,yellow} from '@material-ui/core/colors';
import LoginPage from './components/startup/LoginPage';
import HomePage from './components/startup/HomePage';
import {bizpost} from './ajax';


const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: grey[800],
  //   },
  // },
  palette: {
    // primary: {
    //   main: '#00723f',
    // },
    // secondary: {
    //   main: '#ffc60b',
    // },
    primary: {
      light: '#843e9c',
      main: '#540d6d',
      dark: '#280041',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ffdb99',
      main: '#e1a96a',
      dark: '#ad7a3d',
      contrastText: '#000000',
    },
  },
  
});

const start = async()=>{
  const logincontext = true;
  try{
    const json = {s:'security',a:'basicdata'};
    //startLoading();
    const response = await bizpost(json);
    console.log('login response ',response);
    var page;
    if(!response.bd){
      page = (<LoginPage home={HomePage} apptheme={theme} logincontext={logincontext} />);
    }
    else{
      page = <HomePage  udata={response} />;
    }
  }
  catch(error){
    page = <LoginPage home={HomePage} apptheme={theme} logincontext={logincontext} />;
  }
  finally{
    //stopLoading();
    const container = document.getElementById('app');

    // Create a root.
    if(container !== null){
      const root = ReactDOMClient.createRoot(container);
      root.render(<MuiThemeProvider theme={theme}>{page}</MuiThemeProvider>);
    }
    
    //render(<MuiThemeProvider theme={theme}>{page}</MuiThemeProvider>, document.getElementById('app'));
  }
}

start();
