//import d3 = require("d3@6");

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {purple} from '@material-ui/core/colors';
import {Tooltip, XAxis, YAxis, LineChart, Line, ResponsiveContainer} from 'recharts';
import moment from 'moment';
import * as d3 from "d3";
import data from '../../resources/flare';

const styles = (theme:Theme):any => ({
  paper: {
    backgroundColor: 'white',
    minHeight: 600,
    // height: 150
  },
  div: {
    // height: 95,
    padding: '5px 15px 0 15px'
  },
  header: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightLight,
    color: 'white',
    backgroundColor: purple['600'],
    padding: 5,
  }
});

class Network extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  node:any;
  constructor(props:any) {
    super(props);
    this.state = {
      count:0,
      mode:'amt', //'tot'
    };
    this.generate = this.generate.bind(this);
  }

  componentDidMount() {
    //this.generate()
  }
  componentDidUpdate() {
    this.generate()
  }

  // autoBox0() {
  //   document.body.appendChild(this);
  //   const {x, y, width, height} = this.getBBox();
  //   document.body.removeChild(this);
  //   return [x, y, width, height];
  // }

  autoBox = (svg:any) => () =>{
    document.body.appendChild(svg);
    const {x, y, width, height} = svg.getBBox();
    document.body.removeChild(svg);
    return [x, y, width, height];
  }

  generate(){
    const width = 975;
    const radius = width / 2;

    const tree = d3.cluster().size([2 * Math.PI, radius - 100]);
    //ERNIECommented to avoid typescript bug. TODO; Investigate
    //const root = tree( d3.hierarchy(data).sort((a:any, b:any) => d3.ascending(a.data.name, b.data.name)) );
    const root:any = {}; //tree( d3.hierarchy(data).sort((a:any, b:any) => d3.ascending(a.data.name, b.data.name)) );

    //const svg = d3.create("svg");
    const node = this.node;
    const svg = d3.select(node);

    const radialFn:any = (d:any) => d3.linkRadial().angle((d:any) => d.x).radius((d:any) => d.y);

    svg.append("g")
        .attr("fill", "none")
        .attr("stroke", "#555")
        .attr("stroke-opacity", 0.4)
        .attr("stroke-width", 1.5)
      .selectAll("path")
      .data(root.links())
      .join("path")
        .attr("d", radialFn);
        // .attr("d", d3.linkRadial()
        //     .angle((d:any) => d.x)
        //     .radius((d:any) => d.y));

    svg.append("g")
      .selectAll("circle")
      .data(root.descendants())
      .join("circle")
        .attr("transform", (d:any) => `
          rotate(${d.x * 180 / Math.PI - 90})
          translate(${d.y},0)
        `)
        .attr("fill", (d:any) => d.children ? "#555" : "#999")
        .attr("r", 2.5);

    svg.append("g")
        .attr("font-family", "sans-serif")
        .attr("font-size", 10)
        .attr("stroke-linejoin", "round")
        .attr("stroke-width", 3)
      .selectAll("text")
      .data(root.descendants())
      .join("text")
        .attr("transform", (d:any) => `
          rotate(${d.x * 180 / Math.PI - 90}) 
          translate(${d.y},0) 
          rotate(${d.x >= Math.PI ? 180 : 0})
        `)
        .attr("dy", "0.31em")
        .attr("x", (d:any) => ((d.x < Math.PI) === (!d.children)) ? 6 : -6)
        .attr("text-anchor", (d:any) => ((d.x < Math.PI) === (!d.children)) ? "start" : "end")
        .text((d:any) => d.data.name)
      .clone(true).lower()
        .attr("stroke", "white");

         //svg.attr("viewBox","600,350,600,600");
        const vb = this.autoBox(node)();
        console.log('vb = '+vb);
        //svg.attr("viewBox", this.autoBox(node)().join(","));
        //svg.attr("viewBox","800,600,600,600");
    //return svg.attr("viewBox", autoBox).node();
  }

  ttFormatTime = (stamp:any) => {
    return moment.unix(stamp).format('DD MMM');
  }

  render() {
    const {data,title,classes} = this.props;
    //const {mode} = this.state;

    return (
      <Paper className={classes.paper}>
        <div className={classes.header}>{title}</div>
        <div className={classes.div}>
          <ResponsiveContainer >
            <svg 
              ref={node => this.node = node}
              width={"100%"} height={"100%"}>
            </svg>
          </ResponsiveContainer>
        </div>
      </Paper>
    );
  }
}

Network.propTypes = {
  data: PropTypes.array
};

export default withStyles(styles, { withTheme: true })(Network);
