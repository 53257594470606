import React, { Component, Fragment } from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {blue,pink,green} from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogTitle } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
//import ResourceView from '../view/ResourceView';
import { PDFIcon, CSVIcon, JSONIcon, ZIPIcon} from '../icons';
import Download from '../../ajax/download';
import ClosableBar from '../dialogs/ClosableBar';
import {extendedstyles} from '../../app/styles';
import {bizpost} from '../../ajax';
import {getNames} from '../../app/views/txnconfig';
import moment from 'moment';

const extrastyles = (theme:Theme) => ({
  root: {
    //width: '100%',
    flex: '1 1 auto',
    background: theme.palette.background.paper,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  secondaryHeading2: {
    fontSize: theme.typography.pxToRem(15),
    color: blue[600], //theme.palette.text.secondary,
  },
  topheading: {
    fontWeight:'bold',
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '50%',
  },
  gridtitle: {
    fontSize: theme.typography.pxToRem(15),
    color: pink['500'],
    fontWeight:'bold',
  },
  gridroot: {
    width: '100%',
  },
  gridrow: {
    display: 'flex',
    width: 600,
    border: '1px solid #eeeeee',
    textAlign: 'left',
  },
  gridcolumnauto: {
    flexBasis: 'fill',
    textAlign: 'left',
    borderRight: '1px solid #eeeeee',
  },
  gridcolumn30: {
    flexBasis: 'auto',
    width: '200px',
    textAlign: 'left',
    borderRight: '1px solid #eeeeee',
  },
  gridcontent: {
    textAlign: 'left',
    paddingLeft: '20px',
  },
  dcolumn: {
    width: '100%',
    display: 'flex',
    flexBasis: '50%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.text.hint}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary, //['500'],
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  iconsroot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  customicon: {
    margin: theme.spacing(2),
    color: pink["500"]
  },
  iconHover: {
    margin: theme.spacing(2),
    '&:hover': {
      color: green[200],
    },
  },
  
});

const styles = extendedstyles(extrastyles);

class Preview extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      loaded:false,
      views:[],
      count:0,
      data: []
    };
  }

  //TODO: Move this to src/app/services and keep a dummy failsafe version here.
  formatDate = (stamp:any) => {
    return moment(stamp,'YYYY-MM-DD HH:mm:ss.SSSSSS').format('DD-MMM-YYYY HH:mm');
  }
  //processTransaction = (record:any) => {
  processRecord = (record:any) => {
    // const agent = {title:'Agent',data:[]};
    // const config = {title:'Configuration',data:[]};
    // const payload = {title:'Payload',data:[]};
    // const request = {title:'Request',data:[]};
    // const response = {title:'Response',data:[]};

    // const names = getNames('txn')(true);

    const {req,res} = record;
    const {rqi,xtc, xsc,cln} = record; //resquest
    const {bkn,bkc,brn,brc} = record; //bank
    const {cnm,coc,mbn,idn} = record; //customer
    const {rfn,tri,tdt,dcd,cuc,amt,dtl} = record; //transaction
    const {} = record; //compliance

    const request = [
      {t:'Request ID',v:rqi},
      {t:'Service',v:xtc},
      {t:'Channel',v:cln},
      {t:'Status',v:xsc},
    ];

    const bank = [
      {t:'Bank',v:bkn},
      {t:'Bank Code',v:bkc},
      {t:'Branch',v:brn},
      {t:'Branch Code',v:brc}
    ];

    const customer = [
      {t:'Name',v:cnm},
      {t:'Code',v:coc},
      {t:'Mobile',v:mbn},
      {t:'ID Number',v:idn},
    ];

    const transaction = [
      {t:'Reference ID',v:rfn},
      {t:'Transaction ID',v:tri},
      {t:'Transaction Date',v:this.formatDate(tdt)},
      {t:'Date Created', v:this.formatDate(dcd)},
      {t:'Currency',v:cuc},
      {t:'Amount',v:amt},
      {t:'Details',v:dtl},
    ];

    // const compliance = [
    //   {t:'Status',v:nsc}
    // ]

    const rtn = [
     {title:'Request',data:request},
     {title:'Bank',data:bank},
     {title:'Customer',data:customer},
     {title:'Transaction',data:transaction},
    //  {title:'Compliance',data:compliance},
    ];

    return rtn;
  }

//   procRecord = (record:any) => {
//     const {acc,acn,apn,chn,cur,amt,tcn,} = record;
//     const {csn,cco,mbn,idn} = record;
//     const {att,dcd,tdt,dsc,dtl,rfn,tri,rqi,rst,xsn,xtn,} = record;
//     const {nsc,noi} = record;
// //console.log('acn is ',acn);

//     const account = [
//       {t:'Provider',v:acn},
//       {t:'Service',v:apn},
//       {t:'Channel',v:chn},
//       {t:'Credit',v:cur},
//       {t:'Cost',v:amt},
//       {t:'Center',v:tcn},
//     ];

//     const customer = [
//       {t:'Customer',v:csn},
//       {t:'Code',v:cco},
//       {t:'Mobile',v:mbn},
//       {t:'ID Number',v:idn},
//     ];

//     const testing = [
//       {t:'Record Date',v:dcd},
//       {t:'Test Date',v:tdt},
//       {t:'Description',v:dsc},
//       {t:'Details',v:dtl},
//       {t:'Reference',v:rfn},
//       {t:'Test Reference',v:tri},
//       {t:'Request ID',v:rqi},
//       {t:'Result',v:rst},
//       {t:'Status',v:xsn},
//       {t:'Type',v:xtn},
//       {t:'Attempts',v:att},
//     ];

//     const notification  = [
//       {t:'Status',v:nsc}
//     ];

//     const rtn = [
//      {title:'Account',data:account},
//      {title:'Customer',data:customer},
//      {title:'Testing',data:testing},
//      {title:'Notification',data:notification},
//     ];

//     return rtn;
//   }

  showEmpty = (title:string,classes:any) => {
    return (
      <Fragment>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classnames(classes.heading,classes.topheading)}>
                {title}
              </Typography>
            </div>
            <div className={classes.column}>
              <Typography className={classnames(classes.secondaryHeading,classes.topheading)}>
                {'N/A'}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div>
              N/A
            </div>
            <div></div>
          </AccordionDetails>
        </Accordion>
      </Fragment>        
    )
  }

  showStatus = (record:any, classes:any) => {
    if(!record.xsc) return this.showEmpty('Status',classes);
    return (
      <Fragment>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classnames(classes.heading,classes.topheading)}>
                Status
              </Typography>
            </div>
            <div className={classes.column}>
              <Typography className={classnames(classes.secondaryHeading,classes.topheading)}>
                {record.xsc}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div>
              <Typography className={classnames(classes.secondaryHeading,classes.topheading)}>
                {record.nsc}
              </Typography>
            {(record.noi||[]).map( (infoitem:any,ii:number) => ( //TODO: sort stp in dessc
              <div key={`tsi_${ii}`} className={classes.gridrow}>
                <div className={classes.gridcolumn30}>
                  <Typography>
                    {infoitem.stp}
                  </Typography>
                </div>
                <div className={classes.gridcolumnauto}>
                  <Typography className={classes.secondaryHeading2}>
                    {infoitem.rst}
                  </Typography>
                </div>
              </div>
            ))}
            </div>
            <div></div>
          </AccordionDetails>
        </Accordion>
      </Fragment>        
    )
  }

  showDetails = (rs:any,classes:any) => {
    
    return (
      <Fragment>
      {rs.map( (rsitem:any,rid:number) => (
        <div key={`rs_${rid}`}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>
                {rsitem.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div>
              {rsitem.data.map( (rec:any,idx:number) => (
                <div key={`rs_${rid}_${idx}`}>
                  <div className={classes.column}>
                    <Typography className={classes.heading}>
                      {rec.t}
                    </Typography>
                  </div>
                  <div className={classes.column}>
                    <Typography className={classes.secondaryHeading}>
                      {rec.v}
                    </Typography>
                  </div>
                </div>
              ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
      </Fragment>
    );
  }

  showHistory = (record:any,classes:any) => {
    return (
      <Fragment>
        {(record.his && record.his.length>0)?
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classnames(classes.heading,classes.topheading)}>
              History
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div>
                {record.his.map( (hisitem:any,i:number) => (
                  <div key={`his_${i}`} className={classes.gridrow}>
                  {/* <div key={erritem.field} className={classes.gridrow}> */}
                    <div className={classes.gridcolumn30}>
                      <Typography className={classes.heading}>
                        <span>{hisitem.stp}</span>
                      </Typography>
                    </div>
                    <div className={classes.gridcolumnauto}>
                      <Typography className={classes.secondaryHeading2}>
                        <span>{hisitem.rst}</span>
                      </Typography>
                    </div>
                    <Divider />
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        :""}
      </Fragment>
    );
  }

  showRequest = (record:any,classes:any) => {
    /*
    dsc: "description"
    src: "Console"
    soi: 
    */
    return (
      <Fragment>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classnames(classes.heading,classes.topheading)}>
              Request
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            {/* <div> */}
              {record.req.map((n:any) => {
                let sdoc:any = {};
                let vts:any = {};
                let docs = sdoc[n];
                let dockeys = Object.keys(docs);
                return dockeys.map( (vn,vi) => {
                  let docdata = docs[vn];
                  return (
                    <div key={`doc_${vi}`}>
                      <div className={classes.gridtitle}>{vts[n]||n} - {vn}</div> 
                      <div>
                      {docdata.map( (dataitem:any,di:number) => {
                        return (
                          <div key={`docdata_${di}`} className={classes.gridrow}>
                          {/* <div key={dataitem.field} className={classes.gridrow}> */}
                            <div className={classes.gridcolumn30}>
                              <Typography className={classes.heading}>
                                <span>{dataitem.title}</span>
                              </Typography>
                            </div>
                            <div className={classes.gridcolumnauto}>
                              <Typography className={classes.secondaryHeading2}>
                                <span>{dataitem.value}</span>
                              </Typography>
                            </div>
                            <Divider />
                          </div>
                        );
                      })} 
                      </div>
                    </div>
                  );  
                });
              })}
          </AccordionDetails>
        </Accordion>
      </Fragment>
    );
  }

  showResponse = (record:any,classes:any) => {
    /**
     * loc: "(-0.3361599, 5.639948)"
        mbn: "+233500062919"
        msg: "↵1 Do you have headache?(No)↵2 Are you feeling feverish?(Yes)↵3 Are you coughing?(Yes)↵4 Are you having dry throats?(No)↵5 Would you like to book an appointment?(Yes)"
        rfn: "f93b5157-3f0e-4e12-b5dd-175f687091fc"
        stp: "2020-07-28T22:53:10+00:00"
     */
    return (
      <Fragment>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classnames(classes.heading,classes.topheading)}>
              Response
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div className={classes.gridroot}>
            {record.res.map( (valitem:any,vai:number) => {
              return (
                <div key={`val_${vai}`} className={classes.gridrow}>
                {/* <div key={valitem.validation} className={classes.gridrow}> */}
                  <div className={classes.gridcolumn30}>
                    <Typography className={classes.heading}>
                      <span className={classes.gridcontent}>{valitem.title}</span>
                    </Typography>
                  </div>
                  <div className={classes.gridcolumnauto}>
                    <Typography className={classes.heading}>
                      <span className={classes.gridcontent}>{valitem.validation}</span>
                    </Typography>
                  </div>
                  <div className={classes.gridcolumn30}>
                    <Typography className={classes.secondaryHeading2}>
                      <span className={classes.gridcontent}>{valitem.result}</span>
                    </Typography>
                  </div>
                  <Divider />
                </div>
              );
            })} 
            </div>    
          </AccordionDetails>
        </Accordion>
      </Fragment>
    );
  }

  showDownloads = (record:any,classes:any) => {
    return (
      <Fragment>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classnames(classes.heading,classes.topheading)}>
              Downloads
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div>
              <div className={classes.iconsroot}>
                  <Button variant="contained" className={classes.customicon} onClick={this.onClick('pdf')}>
                    <PDFIcon titleAccess="PDF Download"/>
                  </Button>
                  <Button variant="contained" className={classes.customicon} onClick={this.onClick('csv')}>
                    <CSVIcon titleAccess="CSV Download"/>
                  </Button>
                  <Button variant="contained" className={classes.customicon} onClick={this.onClick('json')}>
                    <JSONIcon titleAccess="JSON Download" />
                  </Button>
                  <Button variant="contained" className={classes.customicon} onClick={this.onClick('zip')}>
                    <ZIPIcon titleAccess="ZIP Download" />
                  </Button>
              </div>
            </div>
            <div></div>
          </AccordionDetails>
        </Accordion>
      </Fragment>
    );
  }

  onClick = (name:any) => () => {
    //var btn = meter.target;
    var {data,record,user} = this.props;
    var d = new Download('','');
    d.downloadFile(name,data,record); //user
  } 

  onClose = (meter:any) => {
    const {onClose} = this.props;
    this.setState({loaded:false});
    if(typeof onClose == 'function') onClose();
  }

  render() {
    const {open,recid,record,onClose,classes } = this.props;
    //const {data} = this.state;
    const title = 'Transaction Record';
    const status = this.showStatus(record,classes);
    //const recordset = this.procRecord(record);
    const recordset = this.processRecord(record);
    const details = this.showDetails(recordset,classes);
    //const txnreq = this.showRequest(record,classes);
    //const txnres = this.showResponse(record,classes);
    const history = this.showHistory(record,classes);
    const downloads = this.showDownloads(record,classes);
    // var sdoc = record.sdoc||{};
    // var vers = Object.keys(sdoc);
    // var vts = {'POI':'ID Verification','POA':'Address Verification'};
    return (
      <Fragment>
        <Dialog open={open}  className={classes.dialog} classes={{paper: classes.dialogcontent}}>
          <DialogTitle className={classes.titlebar}><ClosableBar onClose={this.onClose}>{title}</ClosableBar></DialogTitle>
          <DialogContent className={classes.dialogcontent}>
            <div className={classes.root}>
              {/* call showStatus(record,classes) */}
              {status}
              {/* call showDetails(record,classes) */}
              {details}
              {/* call showHistory(record,classes) */}
              {history}
              {/* call showRequest(record,classes) */}
              {/* call showResponse(record,classes) */}
              {/* call showDownloads(record,classes) */}
              {downloads}
              
              
              
            </div>
          </DialogContent>
          {/* <DialogActions>
            <Button variant="contained" color="secondary" onClick={this.stopLoading}><CloseIcon/>Close </Button>
          </DialogActions> */}
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Preview);
