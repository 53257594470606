//react
import React, { Component,Fragment } from 'react';
import PropTypes from 'prop-types';
//material-ui design helpers
import { Theme, withStyles } from '@material-ui/core/styles';
//material-ui core components
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';


//local helpers
import ColumnPanel from '../panels/ColumnPanel';
import CustomPanel from '../panels/CustomPanel';
import DialogPanel from '../panels/DialogPanel';
import DoublePanel from '../panels/DoublePanel';
import ReportPanel from '../panels/ReportPanel';
import ResultPanel from '../panels/ResultForm';
import SimplePanel from '../panels/SimplePanel';
import TabbedPanel from '../panels/TabbedPanel';
import ViewerPanel from '../panels/ViewerPanel';
import WizardPanel from '../panels/WizardPanel';

// import ServiceForm from './serviceform';
import {apipost,bizpost} from '../../ajax';
//siblings
//import DialogClosableTitle from '../dialogs/DialogClosableTitle';
import {ClosableBar,DraggablePaperComponent} from '../dialogs';
//import DraggablePaperComponent from '../dialogs';
import styles from '../../app/styles';
import {isValid} from '../form';

//
// const posts = {
//   api:apipost,
//   biz:bizpost
// }

//TODO: Move to separate reusable file and import
const winpanels:any = {
  //default: <ServiceForm/>,
  column: <ColumnPanel />,
  custom: <CustomPanel />,
  dialog: <DialogPanel />,
  double: <DoublePanel />,
  report: <ReportPanel />,
  result: <ResultPanel />,
  simple: <SimplePanel />,
  tabbed: <TabbedPanel />,
  viewer: <ViewerPanel />,
  wizard: <WizardPanel />
}
//panelist: ['','dialog','','column','combo','tab','wizard','analytics'],

/**
 * -------------------------------------------------------------------
 * Window Class for action forms
 * -------------------------------------------------------------------
 */
class ServiceWindow extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      open: true,
      valid: props.valid||true,
      formData: props.record||{},
      loading:false,
      panelError:false,
      cuparams: {},
      custatus: false,
      errors:{}
    };
  }

  componentDidMount(){
    const {formData} = this.state;
    this.setValid(formData);
  }

  onPanelError = (status:boolean) => {
    try{
      this.setState({panelError:status,valid:status});
    }
    catch(e){
      console.log('panelError update error: ',e);
    }
  }
  //onError = status => this.setState(ps=>({error:status}));
  //onError = status => {};
  setValid = (data:any) => {
    const {mode,sconfig} = this.props;
    const valid = ['del','delete'].includes(mode)?true:isValid(sconfig,data,mode);
    console.log('valid:',valid);
    this.setState({valid});
  }

  // onChange = name => (value) => {
  //   console.log('wizform.onChange:',name, value,this.props.data);
  //   const {data} = this.props;
  //   const newdata = {...data.data,[name]:value};
  //   this.setValid(newdata);
  //   this.props.setItem('data','data',newdata);
  //   //
  // }

  //onChange = name => (value) => this.setState(prevState=>({formData:{...prevState.formData,[name]:value}})); // this.state.formData[name]=value;
  onChange = (name:string) => (value:any,record:any,childs:any,reason:any) => {
    console.log('onChange.data:',value,this.state.formData);
    const {formData} = this.state;
    const newdata = {...formData,[name]:value};
    this.setValid(newdata);
    //const {cuparams} = this.state;
    //const nuparams = childs?{...cuparams,[name]:value}:cuparams
    //this.setState(prevState=>({formData:{...prevState.formData,[name]:value},cuparams:nuparams}));
    this.setState((ps:any)=>({  formData:{...ps.formData,[name]:value}, ...(childs?{cuparams:{...ps.cuparams,[name]:value}} : {})   }));
    //this.setState({formData:{...this.state.formData,[name]:value},cuparams:{...this.state.cuparams,[name]:vlue}});
  }

  onSubmit = async() => {
    console.log('starting onSubmit... ');
    const {code,service,mode,sconfig,record,onResponse,ft,ms,mt} = this.props;
    const {url,endpoint} = sconfig || {};
    const {formData} = this.state;
    //console.log('onsubmit.formdata:',formData);
    try{
      let s = service;
      let a = mode;
      let recs = (mode==='delete')?{rid:+(record.rid),stp:record.stp}:record;
      let params = Object.assign(recs,formData,{s,a,ft,ms,mt});
      console.log('service request ',params);
      this.startLoading();
      var response;
      if(endpoint==='api')
        response = await apipost(url,code,params);
      else
        response = await bizpost(params);
      //console.log('service response ',response);

      if (response.success){
        this.props.showNotification(response.sm,'success',response.st)
        onResponse(true,response);
      }
      else if(response.failure){
        if (response.en===60){
        }
        else{
          this.props.showNotification(response.message,response.type||'error',response.title)
        }
      }      
    }
    catch(err){
      const error:any = err || {};
      console.log('submit.error:',JSON.stringify(error));
      this.props.showNotification(error.message||"request failed",error.type||'warn',error.title||'Update Error');

    }
    finally{
      this.stopLoading();
    }
  }

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }

  winActions = (mode:any,panel:any,perror:any,onClose:any,onSubmit:any,classes:any) => {
    const {valid} = this.state;
    const close = <Button variant="contained" color="default" onClick={onClose}>Close</Button>;
    const cancel = <Button variant="contained" color="secondary" className={classes.buttons.cancel} onClick={onClose}>Cancel</Button>;
    const submit = <Button variant="contained" color="primary" className={classes.saveButton} disabled={!valid||perror} onClick={onSubmit}>Send</Button>;
    
    const winacts = (panel==='wizard')?null
                  : (perror===true) ? <Fragment>{close}</Fragment>
                  : (mode==='view')?<Fragment>{close}</Fragment>
                  : <Fragment>{cancel}  {submit}</Fragment>;
    
    return winacts;
  }

  maxWidth = (panel:any,swidth:any,theme:any) => {
    //const rsize = theme.breakpoints.up("md");
    if(panel==='column') return "lg";
    if(panel==='wizard'){
      //console.log('arr[swidth]:',)
      if(['xs','sm','md','lg','xl'].includes(swidth)) return swidth; 
      else return 'lg';
    }
    return false;
  }
  
  render() {
    //console.log('in servicewindow.render()');
    const {code,service,title,swidth,open,nodrag,sconfig,mode,data,sdata,panels,record,formatters,onChange,onSubmit,onClose,showNotification,classes,theme,...others} = this.props;
    const {valid,errors,panelError,cuparams} = this.state;
    //console.log('servicewindow.cuparams',cuparams);
    //console.log('servicewindow.onchange',onChange);
    // ...others:{
    // url: "/biz"
    // actions: ƒ (id, rec)
    // actionSet: undefined
    // rowActions: undefined
    // refresh: ƒ (_x)
    // onResponse: ƒ (status, response)
    // handleRecord: undefined
    // setPage: ƒ (page)
    // setHome: ƒ ()
    // }
    //const recActions = actions(null,record); 
    const panel = panels[mode];
    const PanelForm = winpanels[panel]||winpanels.dialog;
    const win = React.cloneElement(PanelForm,{
      code,
      service,
      mode,
      sconfig,
      data,
      sdata,
      record,
      formatters,
      cuparams,
      onClose,
      showNotification,
      onChange:onChange||this.onChange.bind(this),
      onPanelError:this.onPanelError.bind(this),
      ...others
    });
    // window behaviors
    const iswizard = panel==='wizard';
    const iscolumn = panel==='column';
    const showactions = !iswizard;
    const fullwidth = iswizard||iscolumn;
    const maxwidth = this.maxWidth(panel,swidth,theme);
    const winclasses = {}; //{paper:classes.winDialog}
    const wactions = this.winActions(mode,panel,panelError,onClose,onSubmit||this.onSubmit.bind(this),classes);
    const drag = false; //!nodrag;
    const dragging = {PaperComponent:DraggablePaperComponent};

    return (
      <div>
        <Dialog open={open} className={classes.dialog} classes={winclasses} maxWidth={maxwidth} fullWidth={fullwidth} {...(drag?dragging:{})}>
          <DialogTitle id="window-dialog-title" className={classes.titlebar} disableTypography={true}>
            <ClosableBar onClose={onClose}>{title}</ClosableBar>
          </DialogTitle>
          <DialogContent dividers>
            {win}
          </DialogContent>
          {showactions &&
          <DialogActions>
            {wactions}
          </DialogActions>
          }
        </Dialog>
      </div>
    );
  }
}

ServiceWindow.propTypes = {
  service: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool,
  mode: PropTypes.oneOf(['add','edit','delete','view','close','manage','upload','process','wizard']).isRequired,
  sdata: PropTypes.object,
  record: PropTypes.object,
  onClose: PropTypes.func,
  onResponse: PropTypes.func,
  showNotification: PropTypes.func,
  formatters: PropTypes.object
}

//ServiceWindow = withStyles(styles, { withTheme: true })(ServiceWindow);
//ServiceWindow = withMobileDialog()(ServiceWindow);
//ServiceWindow = withStyles(styles, { withTheme: true })(withMobileDialog()(ServiceWindow));

export default withStyles(styles, { withTheme: true })(ServiceWindow);
