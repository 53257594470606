import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {pink} from '@material-ui/core/colors';
import {Tooltip, XAxis, YAxis, LineChart, Line, ResponsiveContainer} from 'recharts';
import moment from 'moment';
import FilterListIcon from '@material-ui/icons/FilterList';
import DateDialog from '../dialogs/DateDialog';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme:Theme):any => ({
  paper: {
    backgroundColor: 'white',
    // minHeight: 400,
  },
  div: {
    //height: 95,
    padding: '5px 15px 0 15px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 24,
    fontWeight: theme.typography.fontWeightLight,
    color: 'white',
    backgroundColor: pink['300'],
    padding: 10,
    justifyContent: 'space-between'
  },
  transaction:{
    color:'#8884d8'
  },
  amount:{
    color:'#4d8888'
  },
  custom_tooltip:{
    lineHeight: 0.2,
    backgroundColor: 'white',
    padding: 5
   },
   filterButton: {
    height: '20px'
  },
  nodata: {
    display: 'flex',
    justifyContent: 'center'
  }
});

class Filter extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      count:0,
      mode:'tot', //'amt'
      lwopen: false,
    };
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.page !== nextProps.page) {
  //       this.setState({page: nextProps.page});
  //   }
  // }

  ttFormatTime = (stamp:any) => {
    return moment.unix(stamp).format('DD MMM');
  }

  customTooltip = ({active, payload, label}: any) => {
    const {classes} = this.props;
    if(payload === null || payload === undefined) return null;
    
     if(active){
      return(
         <div className={classes.custom_tooltip}>
          <p>{this.ttFormatTime(label)}</p>
           <p className={classes.transaction}>{`${payload[0].name} : ${payload[0].value}`}</p>
           <p className={classes.amount}>{`${payload[1].name} : ${'GHS '+payload[1].value}`}</p>
         </div>
       )
     }
     return null;
    }

  handleClick = (meter:any) => {
    this.setState({lwopen:true});
  };

  cancelLogout() {
    this.setState({lwopen:false});
  }

  render() {
    const {data,title='Filter',classes, handleFilter, start,end} = this.props;
    const {mode, lwopen} = this.state;

    return (
      <Paper className={classes.paper}>
        <div className={classes.header}>
          {title} 
          <IconButton
            className={classes.filterButton}
            aria-haspopup={true}
            onClick={this.handleClick}
            color="inherit">
            <FilterListIcon />
          </IconButton>
        </div>
        <div>
          <DateDialog 
            open={lwopen} 
            cancelLogout={this.cancelLogout.bind(this)}
            handleFilter={handleFilter}
            />
        </div>
      </Paper>
    );
  }
}

Filter.propTypes = {
  data: PropTypes.array
};

export default withStyles(styles, { withTheme: true })(Filter);
